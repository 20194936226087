.results {
  /*   min-height: 100vh; */
  background-image: url("tallblurred10.jpg");
  background-color: #a3a3a5b0;
  background-repeat: no-repeat;
  background-attachment: initial;
  background-size: cover;
  background-blend-mode: multiply;
  background-position: top;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

@media (max-width: 800px) {
  .results {
    background-image: url("tallblurred11.jpg");
  }
}

.location {
  align-self: flex-start;
  left: 30px;
  top: 30px;
  position: absolute;
}

.caption-container {
  align-self: center;
}

.cigarette-container {
  align-self: center;
  padding-top: 10px;
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: center;
}

.bigcig {
  height: 30vh;
  max-width: 30px;
}

.smallcig {
  height: 15vh;
  max-width: 30px;
}

@media (max-width: 600px) {
  .bigcig {
    height: 27vh;
    max-width: 30px;
  }

  .smallcig {
    height: 13.5vh;
    max-width: 30px;
  }
}

.smallcig.shrink {
  max-height: 10vh;
}

.caption {
  font-size: 2em;
}

.caption-small {
  font-size: 2rem;
}

.count-small {
  font-size: 3rem;
}

@media (max-width: 500px) {
  .caption-small {
    font-size: 1.7rem;
  }

  .count-small {
    font-size: 1.9rem;
  }
}

@media (max-height: 500px) {
  .cigair-label {
    font-size: 1rem;
  }
}

.shrink {
  max-height: 20vh;
}
.col-lg {
  max-width: 30px;
}

.col-md {
  max-width: 30px;
}

.addpad {
  padding-left: 3px;
}

.center-stuff {
  align-self: center;
  padding-left: 30px;
  padding-right: 30px;
}

.image-container {
  align-items: center;
  display: flex;
}

.footer {
  position: absolute;
  bottom: 15px; /* adjust this value to move link up or down */
  left: 50%; /* center link horizontally */
  transform: translateX(-50%); /* center link horizontally */
}

.search-again {
  position: absolute;
  bottom: 15px;
  right: 13vw;
  transform: translateX(40%); /* center link horizontally */
}

@media (min-width: 900px) {
  .footer {
    width: 20vw;
    bottom: 20px;
  }
  .search-again {
    width: 20vw;
    bottom: 20px;
  }
}

.orange {
  color: rgb(250, 167, 13);
}

.underline {
  text-decoration: underline;
}

.count {
  font-size: 3rem;
}

.grayanchor {
  color: lightgray;
}
a {
  position: absolute;
  /* left: 0; */
  right: 0px;
  z-index: 2;
  bottom: 0;
  font-size: 0.5rem;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
  max-width: 28px;
}

.cigair-label {
  right: 20px;
  top: 10px;
  color: white;
  position: absolute;
}

.gold {
  color: gold;
}
