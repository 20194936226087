#location-input-field {
    min-width: 270px;
    width: 270px;
    border-left: rgb(250, 167, 13);
    border-left-width: 60px;
    border-left-style: solid;
    border-top-style: none;
    border-bottom-style: none;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-bottom-right-radius: 0rem;
    border-top-right-radius: 0rem;
    border-right: white;
}

#input-box {
    display: flex;
}

form input:placeholder-shown+button {
    opacity: 0;
    pointer-events: none;
}

#location-input-field:placeholder-shown {
    width: 300px;
    min-width: 300px;
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.search-clear {
    background-color: white;
    background: white;
    border: white;
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    width: 30px;
    padding-right: 5px;
    padding-left: 0px;
}

#form-holder {
    height: 10vh;
    width: 300px;
    max-width: fit-content;
}

.suggestions-div {
    max-width: 300px;
    width: 300px;
}

*::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}

.bold {
    color: rgb(248, 166, 12);
    font-size: calc(45px + 2vmin);
}

.form-header {
    text-align: center;
}