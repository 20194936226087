.App {
  color: black;
}

.App-logo {
  height: 40vmin;
}

.spinner {
  margin: auto;
}

.search {
  margin-bottom: 1.5rem;
}

.react-mapbox-ac-menu {
  width: auto;
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  border: 1px solid #ccc;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: -1.3rem;
}

.react-mapbox-ac-input {
  margin-bottom: 1.5rem;
}

.react-mapbox-ac-suggestion {
  font-size: 18px;
  margin-bottom: .5rem;
  cursor: pointer;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: gray
}

.react-mapbox-ac-suggestion:hover {
  background-color: #58a;
  color: white;
}

.loader {
  position: absolute;
  flex-direction: column;
  margin: auto;
  width: max-content;
  right: 0;
  left: 0;
}

.App-header {
  background-image: url("cloudsblurred3.jpg");
  background-repeat: no-repeat;
  background-attachment: initial;
  background-size: cover;
  background-blend-mode: multiply;
  background-color: #a3a3a5b0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

#locationInput {
  min-width: 300px;
  border-left: rgb(250, 167, 13);
  border-left-width: 60px;
  border-left-style: solid;
  border-top-style: none;
  border-bottom-style: none;
  visibility: hidden;
}

.bold {
  color: rgb(248, 166, 12);
  font-size: calc(45px + 2vmin);
}

.form-header {
  text-align: center;
}

.App-link {
  color: #09d3ac;
}

.image-container {
  display: inline-block;
}

.img-row {
  align-items: flex-end;
}

.image-container>img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  max-height: 100%;
  max-width: 100%;
  transform: translate(-50%, -50%);
}

/* Main input wrapper */
.algolia-places {
  visibility: hidden;
}

/* The algolia-places input */
.ap-input,
.ap-hint {}

/* The style of the svg icons when the input is on hover */
.ap-input:hover~.ap-input-icon svg,
.ap-input:focus~.ap-input-icon svg,
.ap-input-icon:hover svg {
  float: left;
}

/* The dropdown style */
.ap-dropdown-menu {}

/* The suggestions style */
.ap-suggestion {
  color: black;
}

/* The highlighted names style */
.ap-suggestion em {}

/* The addresses style */
.ap-address {}

.thin {
  max-width: 25px;
}

/* The icons of each suggestions ( can be a building or a pin ) */
.ap-suggestion-icon {
  float: left;
}

/* The style of the svg inside the .ap-suggestion-icon */
.ap-suggestion-icon svg {
  float: left;
  margin-top: 10px;
}

/* The icons inside the input ( can be a pin or a cross ) */
.ap-input-icon {}

/* The style of the svg inside the .ap-input-icon */
.ap-input-icon svg {}

/* .a-cursor is the class a suggestion go on hover */
.ap-cursor {}

/* The style of the svg icon, when the .ap-suggestion is on hover */
.ap-cursor .ap-suggestion-icon svg {}

/* The styles of the Algolia Places input footer */
.ap-footer {
  opacity: 0;
  height: 0px;
}

/* The styles of the Algolia Places input footer links */
.ap-footer a {}

/* The styles of the Algolia Places input footer svg icons */
.ap-footer a svg {}

/* The styles of the Algolia Places input footer on hover */
.ap-footer:hover {
  opacity: 0;
  height: 0px;
}

.about-button {
  left: 10px;
  display: flex;
  position: absolute;
  top: 10px;
}